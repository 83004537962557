import React from "react";
import AnimatedBox from "../../elements/AnimatedBox";
import ListItem from "../../elements/ListItem";
import UnorderedList from "../../elements/UnorderedList";

interface ListProps {
  listItems: string[];
  width?: string[];
  margin?: string[];
  padding?: string[] | string;
  listStyleImage?: string;
  inView: boolean;
}

const List = ({
  listItems,
  width,
  margin,
  listStyleImage,
  padding,
  inView
}: ListProps) => {
  return (
    <UnorderedList margin={margin} width={width} padding={padding}>
      {listItems.map((item, index) => (
        <AnimatedBox doesAnimate={inView} delay={`${index * 0.1}s`} key={index}>
          <ListItem
            listStyleImage={listStyleImage}
            marginBottom="1rem"
            key={item}
            backgroundImage={`url(${listStyleImage})`}
            backgroundSize="1rem"
            backgroundRepeat="no-repeat"
            backgroundPosition="left center"
            paddingLeft="2rem"
          >
            {item}
          </ListItem>
        </AnimatedBox>
      ))}
    </UnorderedList>
  );
};

export default List;
