import styled from "@emotion/styled";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  BackgroundProps,
  background
} from "styled-system";

export interface ListItemProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    LayoutProps,
    BackgroundProps {
  listStyleImage?: string;
}

const ListItem = styled.li<ListItemProps>`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${background}
  list-style-type: ${({ listStyleImage }) =>
    listStyleImage ? "none" : "disc"};
`;

export default ListItem;
