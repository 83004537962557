import React from "react";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import List from "../../components/List";
import Tick from "../../images/AboutUs/tick.png";
import LayoutSection from "../LayoutSection";
import { useInView } from "react-intersection-observer";
import ViewportWrapper from "../ViewportWrapper";
import AnimatedText from "../../elements/AnimatedText";

interface ListSectionProps {
  title: string;
  listItems: string[];
}

const ListSection = ({ title, listItems }: ListSectionProps) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  return (
    <ViewportWrapper ref={ref}>
      <LayoutSection
        backgroundColor={colors.mediumLime}
        padding={["3rem 0", "3rem 0", "5rem 0", "5rem 0", "5rem 0", "8rem 0"]}
      >
        <Box
          margin="auto"
          display="flex"
          flexDirection={["column", "column", "row"]}
        >
          <AnimatedText
            as="h3"
            fontSize={[4, 4, 6, 6, 6, 7]}
            fontWeight="bold"
            margin={["0", "0", "0 2rem 0 0", "0 2rem 0 0", "0 4rem 0 0"]}
            width={["auto", "auto", "50%"]}
            doesAnimate={inView}
          >
            {title}
          </AnimatedText>
          <List
            padding="0"
            listItems={listItems}
            width={["auto", "auto", "50%"]}
            margin={["1rem 0 0", "1rem 0 0", "0"]}
            listStyleImage={Tick}
            inView={inView}
          />
        </Box>
      </LayoutSection>
    </ViewportWrapper>
  );
};

export default ListSection;
