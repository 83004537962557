import React from "react";
import App from "../components/App";
import CoreValues from "../components/CoreValues";
import Hero from "../components/Hero";
import HeroPartner from "../components/HeroPartner";
import SectionTile from "../components/SectionTile";
import colors from "../styles/colors";
import Mike_Awards from "../images/AboutUs/mike_awards.jpg";
import World from "../images/AboutUs/world.svg";
import DataSolution from "../images/AboutUs/datasolution.png";
import Team from "../images/AboutUs/team.png";
import Testimonials from "../components/Testimonials";
import Image from "../elements/Image";
import Box from "../elements/Box";
import Text from "../elements/Text";

import Gallery from "../components/Gallery";
import { PageProps } from "gatsby";
import SEO from "../components/SEO";
import ListSection from "../components/ListSection";
import LayoutSection from "../components/LayoutSection";
import AnimatedText from "../elements/AnimatedText";
import { useInView } from "react-intersection-observer";
import ViewportWrapper from "../components/ViewportWrapper";
import AnimatedBox from "../elements/AnimatedBox";

const acidgreenBenefits = [
  "Over 20 years business experience and success",
  "Multi-Award winning company",
  "Worked with some of the world’s leading retailers, brands and organisations",
  "Approachable, likeable, honest and ethical",
  "Full service provider offering premium eCommerce design, Magento and Shopify development, digital marketing, optimisation and support services",
  "Specialise in creating solutions that convert to sales",
  "Deliver quality solutions of the highest standards",
  "Focus on developing strong ongoing relationships with all our clients",
  "Only employ the most talented staff with exceptional skills and portfolios",
  "Provide premium customer service"
];

// About us is the only page not on contentful
// This was due to the components being unique like core values, working with acidgreen, team grown to 70 etc
// Would look to migrate this to contentful in the future

const AboutUs = ({ location }: PageProps) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  return (
    <App location={location}>
      <SEO
        title={"Magento Development Agency | Magento Australia | acidgreen"}
        url={location.href}
      />

      <Hero
        heading="acidgreen is a colour"
        description="Vibrant, expansive, energetic, memorable and bold (just like our team)"
      ></Hero>
      <HeroPartner
        backgroundColor={colors.mediumLime}
        heading="Celebrating over 20 years of digital commerce excellence"
        variant="secondary"
      />
      <CoreValues />
      <SectionTile
        heading="Multi-Award Winning Full Service Digital Commerce Agency"
        paragraph="With over 70 full time skilled professionals we pride ourselves on the quality of our work and always ensure our clients are entirely satisfied before sign-off. Our approach to digital commerce excellence has afforded us the long standing client relationships we have today with some of the world’s biggest retailers, brands and organisations. Our solutions are built with your unique requirements in mind, customised to fit with your business as you need, and with our dedication to providing you with a quality solution and service."
        image={{ src: Mike_Awards, name: "acidgreen award" }}
        mobileImage={true}
      />
      <ListSection
        title="Why you should work with acidgreen"
        listItems={acidgreenBenefits}
      />
      <ViewportWrapper ref={ref}>
        <LayoutSection
          backgroundColor={colors.lightGrey}
          padding={["2rem 0 0", "2rem 0 0", "2rem 0 0", "4rem 0 0"]}
          flexDirection="column"
          fullInnerWidth
        >
          <AnimatedText
            fontSize={[4, 4, 4, 4, 5]}
            fontWeight="bold"
            marginLeft={["8%"]}
            doesAnimate={inView}
          >
            Team has grown to over 70
          </AnimatedText>
          <Image
            maxWidth="1200px"
            width="100%"
            alignSelf="flex-end"
            src={Team}
          />
        </LayoutSection>
      </ViewportWrapper>
      <Testimonials
        reviewer="Mike Larcher, CEO"
        review="Our passion for creative, technical and innovative digital commerce excellence and exceptional service underlies all of our thoughts and actions."
      />
      <SectionTile
        backgroundColor={colors.lightGrey}
        heading="A Global Company"
        paragraph="In 2019 acidgreen was acquired by leading international digital commerce agency, Datasolution. Datasolution has more than 110 staff, 150 clients and offices around the globe in Paris, Lille, Nantes, Lyon, Toulouse, Bordeaux, Dublin, Hong Kong and New York. This acquisition takes the merged acidgreen/Datasolution team to over 180 staff making it one of the largest and most experienced digital commerce agencies in APAC."
        image={{ src: World, name: "world" }}
        icons={[{ src: DataSolution, name: "data solution" }]}
        mobileImage={true}
      />
      <Gallery />
      <SectionTile
        backgroundColor={colors.mediumLime}
        heading="Let’s go"
        paragraph="Start your project now. We are friendly and approachable so don’t be shy."
        buttonLink="/contact-us"
        buttonText="Contact us"
        buttonVariant="secondary"
        paragraphFont={3}
        fullWidth
        noMargin
      />
    </App>
  );
};

export default AboutUs;
