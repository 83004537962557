import styled from "@emotion/styled";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from "styled-system";

interface UnorderedListProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    LayoutProps {}

const UnorderedList = styled.ul<UnorderedListProps>`
  ${color}
  ${typography}
  ${space}
  ${layout}
`;

export default UnorderedList;
